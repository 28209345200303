@font-face {
    font-family: 'Silo Soft';
    src: url('SiloSoft-Italic.woff2') format('woff2'),
        url('SiloSoft-Italic.woff') format('woff');
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: 'Silo Soft';
    src: url('SiloSoft-ExtraLight.woff2') format('woff2'),
        url('SiloSoft-ExtraLight.woff') format('woff');
    font-weight: 200;
    font-style: normal;
}

@font-face {
    font-family: 'Silo Soft';
    src: url('SiloSoft-Bold.woff2') format('woff2'),
        url('SiloSoft-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Silo Soft';
    src: url('SiloSoft-Regular.woff2') format('woff2'),
        url('SiloSoft-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Silo Soft';
    src: url('SiloSoft-SemiBold.woff2') format('woff2'),
        url('SiloSoft-SemiBold.woff') format('woff');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'Silo Soft';
    src: url('SiloSoft-BoldItalic.woff2') format('woff2'),
        url('SiloSoft-BoldItalic.woff') format('woff');
    font-weight: bold;
    font-style: italic;
}

@font-face {
    font-family: 'Silo Soft';
    src: url('SiloSoft-Light.woff2') format('woff2'),
        url('SiloSoft-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Silo Soft';
    src: url('SiloSoft-LightItalic.woff2') format('woff2'),
        url('SiloSoft-LightItalic.woff') format('woff');
    font-weight: 300;
    font-style: italic;
}

@font-face {
    font-family: 'Silo Soft';
    src: url('SiloSoft-ExtraLightItalic.woff2') format('woff2'),
        url('SiloSoft-ExtraLightItalic.woff') format('woff');
    font-weight: 200;
    font-style: italic;
}

@font-face {
    font-family: 'Silo Soft';
    src: url('SiloSoft-SemiBoldItalic.woff2') format('woff2'),
        url('SiloSoft-SemiBoldItalic.woff') format('woff');
    font-weight: 600;
    font-style: italic;
}

@font-face {
    font-family: 'Silo Soft';
    src: url('SiloSoft-ExtraBolditalic.woff2') format('woff2'),
        url('SiloSoft-ExtraBolditalic.woff') format('woff');
    font-weight: 800;
    font-style: italic;
}

@font-face {
    font-family: 'Silo Soft';
    src: url('SiloSoft-ExtraBold.woff2') format('woff2'),
        url('SiloSoft-ExtraBold.woff') format('woff');
    font-weight: 800;
    font-style: normal;
}

