body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background:#EFEFEF;
  color:#8D8D8D;
}

.navbar{
  -webkit-box-shadow: -1px 0px 5px 0px rgba(212,212,212,1);
  -moz-box-shadow: -1px 0px 5px 0px rgba(212,212,212,1);
  box-shadow: -1px 0px 5px 0px rgba(212,212,212,1);
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
h1, h2,h3,h4,h5,h6{
  color:#000;
  text-transform: uppercase;
  font-weight: bold;
  font-family: 'Silo Soft'
}
/*

Rojo Talisis: fc4137
naranja: EF946C

*/
.highligth-color{
  color:#de4438;
  font-weight: bold;
}
.react-select__indicators, .css-tlfecz-indicatorContainer{
  color:#de4438 !important;
}

.color-blue{
  color:#1F2041;
  font-weight: bold;
}

.caption{
  font-size:12px;
  text-transform: uppercase;
}
.form-profile{
  
}
.progress{
  background-color: #fff;
  border:1px solid #de4438;

}
.form-control{
  /*
  background: #fff4f0;
  */
  font-family: 'Open Sans';
}
.form-control:focus{
  background: #fff4f0;
}

.btn-primary{
  background-color: rgb(0, 0, 0);
  border-color: #000
}
.btn-primary:hover{

  background-color: rgb(70, 70, 70);
}
.btn-secondary{
}
.btn-link{
  color: #000000;
}
.cmd-holder{
  text-align: right;
}
.header-wrap{
  margin: 10px 0;
  display: inline-block;
}
.header-wrap .progress{
height: 4px;
}
.contentWrap{
  display: flex
}
.sidebar{
  width:30px;
  padding: 7px;
  display: flex;
  border-right:thin solid #e0e0e0; 
  position: relative;
}
.sidebar ul{
  padding:0px;
}

.sidebar ul li{
  list-style: none;
  position: relative;
  margin: 2px 0;
}
.sidebar ul li a{
  color:#8D8D8D;
  
  background: #fafafa;
}
.sidebar ul:before{

  content: '';
    display: block;
    height: 85px;
    width: 2px;
    background: #e0e0e0;
    left: 15px;
    top: 20px;
    position: absolute;

  
}
.sidebar ul li:last-child:after { 
  display: none;  
}
.sidebar ul li a.active svg{
 color:#de4438;
}
.sidebar ul li a.active{
  color:#de4438;
  font-weight: bold;
 }
 .sidebar ul li svg.fa-check-circle{
  display:none;
 }
 .sidebar ul li.done svg.fa-check-circle{
  display:inline-block;
 }

.sidebar ul li.done svg.fa-circle{
  display:none;
 }

.sidebar .title{
  display: none;
}
.sidebar .step{
  display: none;
}
.content{
  display: block;
  width: 100%;
  padding: 7px;
}

.boxed-wrap{
  background:#fafafa;
  -webkit-box-shadow: -1px 0px 5px 0px rgba(212,212,212,1);
  -moz-box-shadow: -1px 0px 5px 0px rgba(212,212,212,1);
  box-shadow: -1px 0px 5px 0px rgba(212,212,212,1);
  border-radius: 5px;
}
.title-component h1{
  font-size: 1.5rem;
}
.bg-light{
  background: #fafafa !important;
}
@media screen and (min-width: 765px) {
  .sidebar .step{
    display: inline-block;
  }
  .sidebar .title{
    display: inline-block;
  }
  .sidebar{
    min-width: 200px;
    width:200px;
    padding: 15px;
  }
  .content{
    padding: 15px;
  }
  .title-component h1{
    font-size: 2.5rem
  }
  .sidebar ul:before{
      
      left: 22px;
  
    
  }
}
